import React, { useState } from "react"

import Box from "../../Atomic/Box"
import MonthNavigation from "./MonthNavigation"
import DayLabels from "./DayLabels"
import Dates from "./Dates"

export default function Calendar({ selectedDate, setSelectedDate }) {
  // const [selectedDate, setSelectedDate] = useState(new Date())
  const [activeDate, setActiveDate] = useState(new Date())

  return (
    <Box as={`section`} sx={{ maxWidth: "320px", marginY: "sm" }}>
      <MonthNavigation
        setActiveDate={setActiveDate}
        setSelectedDate={setSelectedDate}
        activeDate={activeDate}
      />
      <DayLabels activeDate={activeDate} />
      <Dates
        activeDate={activeDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setActiveDate={setActiveDate}
      />
    </Box>
  )
}
