import Box from "../../Atomic/Box"

import React from "react"

import isSameMonth from "date-fns/isSameMonth"

export default function Day({
  setSelectedDate,
  setActiveDate,
  activeDate,
  dayConfig,
}) {
  return (
    <Box
      key={dayConfig.text}
      sx={{
        backgroundColor: dayConfig.backgroundColor,
        color: dayConfig.color,
        textAlign: "center",
        justifyContent: "center",
        padding: "2xs",
        cursor: "pointer",
        borderRadius: "full",
      }}
      onClick={() => {
        setSelectedDate(dayConfig.date)
        if (!isSameMonth(dayConfig.date, activeDate)) {
          setActiveDate(dayConfig.date)
        }
      }}
    >
      {dayConfig.text}
    </Box>
  )
}
