import Box from "../../Atomic/Box"
import Flex from "../../Atomic/Flex"
import Text from "../../Atomic/Text"

import React from "react"

export default function Card({ heading, date, children }) {
  return (
    <Box
      as={`li`}
      sx={{
        borderRadius: "md",
        backgroundColor: "greyScale.4",
        color: "greyScale.1",
        padding: "sm",
        marginY: "sm",
      }}
    >
      <Flex sx={{ justifyContent: "space-between" }}>
        <Text sx={{ fontSize: "xl", color: "white" }}>{heading}</Text>
        <Text sx={{ fontSize: "sm" }}>{date}</Text>
      </Flex>
      {children}
    </Box>
  )
}
