import Box from "../../Atomic/Box"
import Flex from "../../Atomic/Flex"
import Text from "../../Atomic/Text"

import React from "react"

import subMonths from "date-fns/subMonths"
import addMonths from "date-fns/addMonths"
import format from "date-fns/format"

export default function MonthNavigation({
  activeDate,
  setActiveDate,
  setSelectedDate,
}) {
  return (
    <Flex>
      <Box
        sx={{ marginRight: "sm", color: "white" }}
        onClick={() => {
          setSelectedDate(new Date())
          setActiveDate(new Date())
        }}
      >
        Today
      </Box>
      <Box
        sx={{
          cursor: "pointer",
          "&:hover": { backgroundColor: "greyScale.2" },
          paddingX: "sm",
          color: "white",
        }}
        onClick={() => {
          setActiveDate(subMonths(activeDate, 1))
        }}
      >
        {"<"}
      </Box>
      <Box
        sx={{
          cursor: "pointer",
          "&:hover": { backgroundColor: "greyScale.2" },
          paddingX: "sm",
          color: "white",
        }}
        onClick={() => {
          setActiveDate(addMonths(activeDate, 1))
        }}
      >
        {">"}
      </Box>
      <Text sx={{ color: "white" }}>{format(activeDate, "MMMM yyyy")}</Text>
    </Flex>
  )
}
