import Box from "../../Atomic/Box"
import Flex from "../../Atomic/Flex"
// import Text from "../../Atomic/Text"

import React from "react"

export default function Booker() {
  return (
    <Flex as={`form`} sx={{ flexDirection: "column" }}>
      {/* <Text
        as={`label`}
        sx={{ color: "white", marginY: "xs" }}
      >{`Händelsens namn`}</Text> */}
      <Box
        id={`event-title`}
        name={`title`}
        as={`input`}
        sx={{ borderRadius: "lg", paddingX: "xs" }}
        placeholder={`Händelsenamn`}
      />
      {/* <Text
        as={`label`}
        sx={{ color: "white", marginY: "xs" }}
      >{`Beskriv händelsen`}</Text> */}
      <Box
        id={`event-text`}
        name={`text`}
        as={`textarea`}
        rows={3}
        cols={32}
        sx={{ borderRadius: "lg", paddingX: "xs", resize: "none" }}
        placeholder={`Skriv en kort beskrivning`}
      />
      <Flex sx={{ justifyContent: "center" }}>
        <Box
          as={`button`}
          ariaLabel={`add-event`}
          sx={{
            marginY: "xs",
            padding: "xs",
            color: "white",
            backgroundColor: "greyScale.4",
            border: "none",
            borderRadius: "md",
            maxWidth: "256px",
          }}
          onClick={() => {
            console.log("send")
          }}
        >{`Lägg till händelse`}</Box>
      </Flex>
    </Flex>
  )
}
