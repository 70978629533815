import Box from "../../Atomic/Box"

import React from "react"

export default function List({ children }) {
  return (
    <Box as={`ul`} sx={{ listStyleType: "none" }}>
      {children}
    </Box>
  )
}
