import Grid from "../../Atomic/Grid"

import startOfMonth from "date-fns/startOfMonth"
import endOfMonth from "date-fns/endOfMonth"
import startOfWeek from "date-fns/startOfWeek"
import endOfWeek from "date-fns/endOfWeek"
import addDays from "date-fns/addDays"
import differenceInCalendarWeeks from "date-fns/differenceInCalendarWeeks"

import React from "react"

import Week from "./Week"

export default function Dates({
  activeDate,
  selectedDate,
  setSelectedDate,
  setActiveDate,
}) {
  const startOfTheSelectedMonth = startOfMonth(activeDate)
  const endOfTheSelectedMonth = endOfMonth(activeDate)
  const startDate = startOfWeek(startOfTheSelectedMonth)
  const endDate = endOfWeek(endOfTheSelectedMonth)

  let currentDate = startDate

  const diff = differenceInCalendarWeeks(endDate, startDate)
  const weeks = [...Array(diff + 1)].map((_, i) => i * 7)

  const allWeeks = []

  while (currentDate <= endDate) {
    allWeeks.push(
      <Week
        // key={}
        date={currentDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setActiveDate={setActiveDate}
        activeDate={activeDate}
      />
    )
    currentDate = addDays(currentDate, 7)
  }

  return (
    <Grid columns={7} gap={7}>
      {/* {allWeeks} */}
      {weeks.map(week => {
        return (
          <Week
            key={week}
            date={addDays(startDate, week)}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setActiveDate={setActiveDate}
            activeDate={activeDate}
          />
        )
      })}
    </Grid>
  )
}
