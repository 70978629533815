import Box from "../../Atomic/Box"
import Grid from "../../Atomic/Grid"

import React from "react"

import startOfWeek from "date-fns/startOfWeek"
import addDays from "date-fns/addDays"
import format from "date-fns/format"

export default function DayLabels({ activeDate }) {
  const weekStartDate = startOfWeek(activeDate)
  const weekdays = []

  for (let day = 0; day < 7; day++) {
    weekdays.push(format(addDays(weekStartDate, day), "E"))
    // weekdays.push(<Box>{format(addDays(weekStartDate, day), "E")}</Box>)
  }

  return (
    <Grid columns={7} gap={7}>
      {weekdays.map(label => {
        return (
          <Box
            sx={{
              textAlign: "center",
              justifyContent: "center",
              color: "white",
            }}
            key={label}
          >
            {label}
          </Box>
        )
      })}
    </Grid>
  )
}
