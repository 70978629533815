import Box from "../../components/Atomic/Box"
import Flex from "../../components/Atomic/Flex"
import Header from "../../components/Header"

import { Booking } from "../../components/Planner"

import React from "react"

export default function planner() {
  return (
    <Box sx={{ padding: "sm" }}>
      <Header headerText={`FAMILJEKALENDER`} />
      <Flex sx={{ justifyContent: "center" }}>
        <Booking />
      </Flex>
    </Box>
  )
}
