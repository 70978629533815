import React from "react"

import Day from "./Day"

import isPast from "date-fns/isPast"
import isSameMonth from "date-fns/isSameMonth"
import isSameDay from "date-fns/isSameDay"
import format from "date-fns/format"
import addDays from "date-fns/addDays"
import subDays from "date-fns/subDays"

export default function Week({
  date,
  selectedDate,
  setSelectedDate,
  setActiveDate,
  activeDate,
}) {
  let currentDate = date

  const dayOrder = [...Array(7)].map((_, i) => i)

  const week = dayOrder.map(() => {
    currentDate = addDays(currentDate, 1)
    const cloneDate = currentDate
    let color = "white"

    if (
      isPast(subDays(currentDate, -1)) &&
      isSameMonth(currentDate, selectedDate)
    ) {
      color = "greyScale.2"
    }
    if (!isSameMonth(currentDate, selectedDate)) {
      color = "greyScale.3"
    }

    return {
      text: format(currentDate, "d"),
      backgroundColor: isSameDay(currentDate, selectedDate)
        ? "greyScale.6"
        : "greyScale.5",
      color: color,
      date: cloneDate,
    }
  })

  return (
    <>
      {week.map((day, index) => {
        return (
          <Day
            key={index}
            setSelectedDate={setSelectedDate}
            setActiveDate={setActiveDate}
            activeDate={activeDate}
            dayConfig={day}
          />
        )
      })}
    </>
  )
}
