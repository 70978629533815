import React, { useState, useEffect } from "react"

import Box from "../Atomic/Box"

import Calendar from "./Calendar"

import Card from "./Events/Card"
import List from "./Events/List"
import Booker from "./Events/Booker"

import format from "date-fns/format"

export default function Booking() {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [events, setEvents] = useState([])

  useEffect(() => {
    async function fetchSelectedDate() {
      try {
        const data = await fetch(
          `http://localhost:5000/api/calendar?date=${format(
            selectedDate,
            "yyyyMMdd"
          )}`
        ).then(res => res.json())
        setEvents(data?.data)
      } catch (e) {
        console.error(e)
      }
    }
    fetchSelectedDate()
  }, [selectedDate, setEvents])

  console.log(events)

  return (
    <Box>
      <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

      <List>
        {events.map((event, index) => {
          return (
            <Card
              key={index}
              heading={event.title}
              date={format(selectedDate, "yyyy MM dd HH:mm")}
            >
              {event.text}
            </Card>
          )
        })}
      </List>
      <Booker />
    </Box>
  )
}
